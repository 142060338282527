import {ImageBackground, StyleSheet, View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {scaleSize, toPriceStr} from '@/utils';
import globalStore from '@/services/global.state';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface HeaderType {
  amount?: number | 0;
}

const Header = (props: HeaderType) => {
  const {amount = 0} = props;
  const {i18n} = useTranslation();
  return (
    <ImageBackground
      source={require('@assets/imgs/card-background-image.webp')}
      resizeMode={'cover'}
      style={[
        theme.flex.row,
        theme.margin.lrl,
        theme.flex.centerByCol,
        theme.flex.between,
        theme.padding.leftl,
        styles.container,
      ]}>
      <View style={[theme.margin.leftxxl]}>
        <Text color={theme.fontColor.brown}>
          {i18n.t('transfer-page.label.total')}
        </Text>
        <Text
          style={[theme.margin.tops]}
          color={theme.fontColor.brown}
          fontSize={20}
          fontFamily="fontInterBold">
          {toPriceStr(amount, {
            thousands: true,
            fixed: 2,
            spacing: true,
            currency: globalStore.currency,
          })}
        </Text>
      </View>
      <View
        style={{
          backgroundColor: '#E5D776',
          borderTopLeftRadius: theme.borderRadiusSize.xl,
          borderBottomLeftRadius: theme.borderRadiusSize.xl,
          width: scaleSize(90),
          height: scaleSize(45),
        }}
      />
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    height: scaleSize(108),
    overflow: 'hidden',
    borderTopLeftRadius: theme.borderRadiusSize.l,
    borderTopRightRadius: theme.borderRadiusSize.l,
  },
});

export default Header;
