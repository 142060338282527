import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {scaleSize, toPriceStr} from '@/utils';
import React from 'react';
import {View, StyleSheet, ImageBackground} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../hooks/size.hooks';

const TransferBalance = ({
  balance = 0,
  onGotoRecords,
}: {
  balance: number;
  onGotoRecords?: () => void;
}) => {
  const {i18n} = useTranslation();

  const {screenWidth, screenHeight, designWidth} = useScreenSize();
  const rightIconSize = (14 * screenWidth) / designWidth;
  const itemWidth = (72 * screenWidth) / designWidth;
  const itemHeight = (40 * screenWidth) / designWidth;
  const {} = React.useMemo(() => {
    return {
      screenWidth,
      screenHeight,
      designWidth,
      rightIconSize,
      itemWidth,
      itemHeight,
    };
  }, [
    screenWidth,
    screenHeight,
    designWidth,
    rightIconSize,
    itemWidth,
    itemHeight,
  ]);

  return (
    <ImageBackground
      resizeMode={'cover'}
      source={require('@assets/imgs/card-background-image.webp')}
      style={[
        styles.container,
        theme.margin.lrl,
        theme.padding.leftl,
        theme.flex.centerByRow,
      ]}>
      <View style={[theme.flex.row, theme.flex.between]}>
        <View style={[theme.flex.flex1, theme.flex.centerByRow]}>
          <Text color={theme.fontColor.brown} style={[styles.opacity]}>
            {i18n.t('transfer-page.label.balance')}
          </Text>
          <View
            style={[theme.flex.row, theme.flex.alignEnd, theme.margin.tops]}>
            <Text
              fontFamily="fontInter"
              blod
              fontSize={20}
              style={[theme.font.brown]}>
              {toPriceStr(balance, {
                thousands: true,
                spacing: true,
                currency: globalStore.currency,
              })}
            </Text>
          </View>
        </View>
        <NativeTouchableOpacity
          activeOpacity={0.8}
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={onGotoRecords}>
          <View
            style={[
              theme.flex.alignEnd,
              theme.padding.l,
              {
                backgroundColor: '#E5D776',
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
              },
            ]}>
            <ImageBackground
              source={require('@components/assets/icons/button-backImg-primary.webp')}
              resizeMode={'stretch'}
              style={[theme.flex.center, theme.padding.l]}>
              <Text blod color={theme.fontColor.white}>
                {i18n.t('label.transfer')}{' '}
                {i18n.t('recharge-page.label.records')}
              </Text>
            </ImageBackground>
          </View>
        </NativeTouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  cardBg: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  container: {
    height: scaleSize(106),
    borderTopLeftRadius: theme.borderRadiusSize.l,
    borderTopRightRadius: theme.borderRadiusSize.l,
    position: 'relative',
    overflow: 'hidden',
  },
  bottomContainer: {
    borderRadius: 8,
    padding: 8,
    overflow: 'hidden',
  },
  opacity: {
    opacity: 0.7,
  },
  bgBox: {
    width: '100%',
    height: '100%',
  },
  balanceContainer: {
    paddingHorizontal: 8,
    // paddingTop: 20,
    alignItems: 'center',
  },
  methodContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    // paddingHorizontal: 20,
  },
});

export default TransferBalance;
