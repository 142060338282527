import theme from '@/style';
import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {useInnerStyle} from './wallet.hooks';
import globalStore from '@/services/global.state';
import WalletTotalWallet from './wallet-total-wallet';
import WalletActivities from './wallet-activities';
import WalletTransfer from './wallet-transfer';
import {getWalletAmountList} from './wallet.service';
import {goTo, goBack} from '@/utils';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {useRoute} from '@react-navigation/native';
import {BasicObject} from '@/types';
import {LazyImageLGBackground} from '@basicComponents/image';
import {useTranslation} from 'react-i18next';

const WalletPage = () => {
  const {i18n} = useTranslation();
  const {walletStyle} = useInnerStyle();
  const [login, setLogin] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const allAmount = useRef<number>();
  const [thirdAmount, setThirdAmount] = useState(0);
  const [mainAmount, setMainAmount] = useState(0);
  // const [walletList, setWalletList] = useState<WalletAmountListItem[]>([]);
  const refreshWallet = (loading = true) => {
    loading && globalStore.globalLoading.next(true);
    if (login) {
      globalStore.updateAmount.next();
    }
    getWalletAmountList()
      .then(info => {
        const _amount = info.totalCreditAmount;
        if (allAmount.current == null) {
          allAmount.current = _amount;
        }
        setThirdAmount(_amount);
        setMainAmount(allAmount.current - _amount);
        // setWalletList(info.gamesList);
      })
      .finally(() => {
        globalStore.globalLoading.next(false);
      });
  };
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      if (token) {
        refreshWallet();
      }
    });
    const moneySub = globalStore.amountChanged.subscribe(res => {
      setAmount(res.current);
    });

    return () => {
      sub.unsubscribe();
      moneySub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toRecharge = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const route = useRoute();

  return (
    <LazyImageLGBackground style={[walletStyle.page, theme.position.rel]}>
      <DetailNavTitle
        title={i18n.t('wallet.title')}
        hideServer={true}
        hideAmount={true}
        onBack={
          (route.params as BasicObject)?.hideBack ? undefined : () => goBack()
        }
      />

      <ScrollView style={[theme.flex.flex1]}>
        <View style={[walletStyle.container]}>
          <WalletTotalWallet
            login={login}
            amount={amount}
            onRecharge={toRecharge}
          />
          <View style={[theme.background.lightGrey, theme.padding.lrl]}>
            <WalletTransfer
              login={login}
              mainAmount={mainAmount}
              thirdAmount={thirdAmount}
              onRecharge={toRecharge}
              onRefreshTransfer={refreshWallet}
            />
            {/* <WalletList walletList={walletList} /> */}
            <WalletActivities />
          </View>
        </View>
      </ScrollView>
    </LazyImageLGBackground>
  );
};

export default WalletPage;
