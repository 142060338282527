import theme from '@/style';
import React, {useEffect, useRef} from 'react';
import {
  FlatList,
  Image,
  ImageBackground,
  ImageSourcePropType,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
} from 'react-native';
import {useInnerStyle} from './vip.hooks';
import {
  VipProgressInfo,
  VipRenderType,
  maxVipLevel,
  // useVipProgressList,
  // vipColors,
  vipOptionsMap,
  VipProgress,
} from '@/components/business/vip';
import Text from '@basicComponents/text';
import {scaleSize, toPriceStr} from '@/utils';
// import LazyImage from '@/components/basic/image';
// import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
// import {
//   boxReachedIcon,
//   boxReceivedIcon,
//   boxUnReachIcon,
// } from '@/common-pages/vip/vip.variable';
import {IVipItem} from '@/services/global.service';
// import Button from '@basicComponents/button';
// import globalStore from '@/services/global.state';
// import {postReceiveBox} from './vip.service';
import {useTranslation} from 'react-i18next';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';

export interface VipCardListProps {
  rechargeAmount: number;
  vipInfoList: VipProgressInfo[];
  cards: VipRenderType[];
  vipList: IVipItem[];
  onRecharge?: () => void;
  onCheck?: (index: number) => void;
  onRefresh?: () => void;
  checkIndex?: number;
  currentLevel: number;
}

const VipCardList: React.FC<VipCardListProps> = ({
  // vipInfoList,
  // cards,
  vipList,
  // currentLevel,
  rechargeAmount,
  onRecharge,
  onCheck,
  // onRefresh,
  checkIndex,
}) => {
  const {i18n} = useTranslation();
  const {
    vipStyle,
    cardStyle,
    size: {vipCardWidth},
  } = useInnerStyle();
  const ref = useRef<FlatList>(null);
  // const renderProgressList = useVipProgressList(vipInfoList);
  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const width = vipCardWidth;
    const x = e.nativeEvent.contentOffset.x;
    const nowIndex = Math.round(x / width);
    onCheck?.(nowIndex);
  };
  // const handleReceive = async (item: IVipItem) => {
  //   if (item.statusReached === 1 && item.rewardReceivingStatus === 1) {
  //     try {
  //       globalStore.globalLoading.next(true);
  //       const userInfo = await postUserInfo();
  //       await postReceiveBox(userInfo.userId, item.level);
  //       globalStore.globalSucessTotal(i18n.t('vip.success'));
  //       globalStore.updateAmount.next();
  //       globalStore.doNotices.next();
  //       onRefresh?.();
  //     } finally {
  //       globalStore.globalLoading.next(false);
  //     }
  //   }
  // };
  useEffect(() => {
    if (vipList.length <= 0) {
      return;
    }
    ref.current?.scrollToIndex({
      index: checkIndex || 0,
    });
  }, [checkIndex, vipList.length]);

  const renderVipCardItem = ({item, index}: ListRenderItemInfo<IVipItem>) => {
    return (
      <ImageBackground
        source={require('@assets/imgs/card-background-image-house.webp')}
        style={[
          cardStyle.cardContainerStyle,
          theme.flex.row,
          theme.flex.between,
          theme.padding.lrl,
          theme.flex.centerByCol,
        ]}
        key={index}>
        <View
          style={[
            theme.flex.flex1,
            theme.flex.col,
            theme.margin.rightl,
            theme.flex.end,
            theme.fill.fillH,
          ]}>
          {index !== 0 && (
            <View style={[cardStyle.topTag]}>
              <Text fontSize={theme.fontSize.s} color={theme.fontColor.brown}>
                {vipList[index - 1].statusReached === 1
                  ? vipList[index - 1].rewardReceivingStatus === 0
                    ? i18n.t('vip.received')
                    : i18n.t('vip.collected')
                  : i18n.t('vip.finished')}
              </Text>
            </View>
          )}
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.margin.topxs,
            ]}>
            <Text
              fontSize={24}
              fontFamily="fontDin"
              blod
              // color={vipColors[index]}
              color={theme.fontColor.brown}>
              V{index}
            </Text>
            <NativeTouchableOpacity
              activeOpacity={0.8}
              style={[theme.flex.centerByCol]}
              onPress={onRecharge}>
              <ImageBackground
                source={require('@components/assets/icons/button-backImg-primary.webp')}
                resizeMode={'stretch'}
                style={[
                  theme.flex.center,
                  theme.padding.lrl,
                  theme.padding.tbxs,
                  theme.margin.leftl,
                ]}>
                <Text blod color={theme.fontColor.white}>
                  {i18n.t('vip.recharge')}
                </Text>
              </ImageBackground>
            </NativeTouchableOpacity>
          </View>
          <View style={[theme.margin.topxs]}>
            <VipProgress
              currentLevel={item?.level}
              nextCurrentLevel={item?.level + 1}
              current={rechargeAmount}
              total={item?.amount}
              hasCurrentText={true}
            />
          </View>

          <View
            style={[theme.position.rel, theme.flex.row, theme.margin.topxs]}>
            <Text main fontSize={theme.fontSize.s} blod>
              {toPriceStr(rechargeAmount, {fixed: 0, thousands: true})}
            </Text>
            <Text
              main
              fontSize={theme.fontSize.s}
              blod
              style={[theme.margin.leftxxs]}>
              / {toPriceStr(item.amount, {fixed: 0, thousands: true})}(V
              {Math.min(index + 1, maxVipLevel)})
            </Text>
          </View>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.margin.btml,
              theme.fill.fillW,
              theme.padding.topxs,
            ]}>
            <Text
              color={theme.fontColor.brown}
              style={[cardStyle.opacity, cardStyle.text]}
              fontSize={theme.fontSize.xs}>
              {i18n.t('vip.recharge')}{' '}
              <Text blod>
                {toPriceStr(item.diff, {fixed: 0, thousands: true})}
              </Text>{' '}
              {i18n.t('vip.move')} VIP
              {Math.min(item.level + 1, maxVipLevel)}
            </Text>
          </View>
        </View>
        <Image
          source={vipOptionsMap[item?.level].small as ImageSourcePropType}
          style={[
            {
              height: scaleSize(75),
              width: scaleSize(98),
            },
          ]}
        />
      </ImageBackground>
    );
  };
  return (
    <FlatList
      ref={ref}
      style={[vipStyle.cardList]}
      showsHorizontalScrollIndicator={false}
      onScroll={handleScroll}
      contentContainerStyle={[vipStyle.cardListContent]}
      data={vipList}
      pagingEnabled
      snapToAlignment="center"
      renderItem={renderVipCardItem}
      getItemLayout={(data, index) => ({
        length: vipCardWidth + theme.paddingSize.l,
        offset: (vipCardWidth + theme.paddingSize.l) * index,
        index,
      })}
      horizontal
    />
  );
};

export default VipCardList;
